import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { Button, Dropdown, Modal, Image, DropdownButton, MenuItem } from 'react-bootstrap'
import classnames from 'classnames'
import { toastr } from 'react-redux-toastr'
import Toggle from 'react-toggle'
import _ from 'lodash'
import FileSaver from 'file-saver'

import EditAssetDeviceInfo from './EditAssetDeviceInfo'
import EditAssetDeviceSettings from './EditAssetDeviceSettings'
import VehicleDataThreshold from '../../../pages/vehicles/VehicleDataThreshold'
import ModalDeviceReplace from '../modals/ModalDeviceReplace'
import ModalProvisionDevice from '../modals/ModalProvisionDevice'
import ConfigModal from '../modals/ConfigModal'
import VehicleDeleteModal from '../modals/VehicleDeleteModal'
import AddInstallReportModal from '../modals/AddInstallReportModal'
import LabelTag from '../../dump/LabelTag'
import CameraEnableToggle from '../../../pages/vehicles/CameraEnableToggle'
import ModalChangeConfBatch from '../modals/ModalChangeConfBatch'
import DeviceArchiveHistory from '../../../pages/vehicles/DeviceArchiveHistory'
import ModalInstallReport from '../modals/ModalInstallReport'
import AdasCalibrationSettings from '../../../pages/vehicles/AdasCalibrationSettings'
import GreyHeader from '../../dump/menuComponents/GreyHeader'
import IconTooltip from '../../dump/menuComponents/IconTooltip'
import CustomButton from '../../CustomButton'
import ModalDeviceOfflineDisabled from '../../../pages/vehicles/ModalDeviceOfflineDisabled'

import { isDv6, isInternalUser, isInternalUserWithPartner, constants } from '../../helpers/constants'
import { vehicleIcons, detectDeviceStatus } from '../../helpers/helperVehicle'
import { formatTimestamp, formatOnlineStatusLocal } from '../../helpers/tablesFuncHelpers'
import { isCustomUserPermissions } from '../../helpers/helperUsers'
import { getDeviceById, updateDevice } from '../../../action/DeviceActions'
import { getCompanyGroups } from '../../../action/GroupsActions'
import { getCompanyGeotabData, setWebfleetCard } from '../../../action/GeotabActions'
import { getConfigFile, pushConfigFile } from '../../../action/FleetAdminActions'
import { apiCallGet } from '../../../action/RouterActions'

import '../../dump/menuComponents/menuComponents.sass'
import '../../dump/menuComponents/ManageVehicles.sass'
import 'react-dual-listbox/lib/react-dual-listbox.css'


const EditAssetContainer = (props) => {
	const { user, company, location, params, toggleLoader } = props;
	const userRole = user.roles[0]
	const userPermissions = user.permissions

	const [state, setState] = useState({
		deviceId: (!_.isEmpty(location.state) && location.state.id) || (!_.isEmpty(params) && params.id) || 'undefined',
		initialData: null,
		data: {},
		errMessage: {
			errvehicle_name: '',
			errvehicle_icon: '',
			errgroups: '',
			errdata_limit: '',
		},
		confirmCanbus: false,
		provisionType: null,
		showModalProvisionDevice: false,
		showConfigModal: false,
		showDeleteModal: false,
		showAddInstallReportModal: false,
		showModalInstallReport: false,
		showModalDeviceArchiveHistory: false,
	})
	const [showModalApplyProfile, setShowModalApplyProfile] = useState(false);
	const [showConfirmCanbusModal, setshowConfirmCanbusModal] = useState(false);
	const [showModalDeviceReplace, setShowModalDeviceReplace] = useState(false);
	const [groupsList, setGroupsList] = useState([]);
	const [geotabCredentials, setGeotabCredentials] = useState(null);
	const [webfleetCredentials, setWebfleetCredentials] = useState(null);
	const [devicesOfflineDisabled, setDevicesOfflineDisabled] = useState('')

	useEffect(() => {
		getDevice();
	}, [])

	useEffect(() => {
		if (state.data.company_name || state.data.partner_name) {
			getGroupsList()
			getCompanyGeotabCredentials()
			getCompanyWebfleetCredentials()
		}
	}, [state.data.device_id])

	const getDevice = () => {
		// if (location) {
		// const deviceId = (!_.isEmpty(location.state) && location.state.id) || (!_.isEmpty(params) && params.id)
		toggleLoader(true);
		const attributes = {
			deviceId: state.deviceId,
			userRole,
			geotab: true,
			modem: true,
			includeData: 'replace,remove',
			externalSrv: 'webfleet',
		}
		// getDeviceById(deviceId, userRole, true, true, true, true)
		getDeviceById(attributes)
			.then(res => {
				console.log('EditAssetContainer getDeviceById res: ', res)
				const deviceData = res.data.response.device
				deviceData.status = detectDeviceStatus(deviceData)

				toggleLoader(false);
				setState({
					...state,
					data: { ...state.data, ...deviceData },
					initialData: deviceData,
				})
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('EditAssetContainer getDeviceById error: ', error.response, error)
				toastr.error('Unable to retrieve device settings. Please try again later.')
			})
		// }
	}

	const getGroupsList = () => {
		toggleLoader(true)
		const company = {
			name: state.data.company_name,
			partner: state.data.partner_name,
		}
		getCompanyGroups(userRole, company)
			.then(res => {
				toggleLoader(false)
				const groups = res.data.response.groups.filter(item => item.toLowerCase() !== 'installed devices');
				setGroupsList(groups);
			})
			.catch(error => {
				toggleLoader(false)
				console.log('!getCompanyGroups error', error.response);
				toastr.error('There was an issue getting company groups. Please try again later.')
			})
	}

	const getCompanyGeotabCredentials = () => {
		// const { location } = props;
		// if (location) {
		getCompanyGeotabData({
			// partner_name: location.state.company.partner,
			// company_name: location.state.company.name,
			company_name: state.data.company_name,
			partner_name: state.data.partner_name,
			get_p: true,
		})
			.then(async (res) => {
				// setState({ ...state, geotab_company: await res.data.response })
				setGeotabCredentials(res.data.response)
			})
			.catch((error) => {
				// NO GEOTAB COMPANY
				//* if it returns 'company does not exists' then this company does not belong to any geotab company
				console.log('!getCompanyGeotabData error: ', error.response)
				toastr.error('There was an issue getting company geotab credentiqals. Please try again later.')
			})
		// }
	}

	const getCompanyWebfleetCredentials = () => {
		apiCallGet('/external_service/webfleet/account', { company_id: state.data.company_id })
			.then(res => {
				// setState({ ...state, webfleetCredentials: res.data.response })
				setWebfleetCredentials(res.data.response)
			})
			.catch((error) => {
				// NO WEBFLEET COMPANY
				//* if it returns 'company does not exists' then this company does not belong to any webfleet company
				console.log('!getCompanyWebfleetCredentials error: ', error.response)
				toastr.error('There was an issue getting company webfleet credentiqals. Please try again later.')
			})
	}

	const goBack = () => {
		if (location && location.state && location.state.type) {
			switch (location.state.type) {
				case 'reports':
					browserHistory.push({ pathname: '/reports' });
					break;
				case 'vehicle_sidebar':
					browserHistory.push({ pathname: '/', state: { id: location.state.id, company: location.state.company, type: 'return_to_map' } });
					break;
				case '/event':
				case '/managedrivers':
					browserHistory.push({ pathname: location.state.type })
					break;
				default:
					browserHistory.push('/managevehicles')
					break;
			}
		}
		else {
			browserHistory.push('/managevehicles')
		}
	}

	const saveVehicle = (confirmCanbus) => {
		const { data, initialData } = state;
		// const { company } = location.state;
		const company = {
			name: data.company_name,
			partner: data.partner_name,
		}
		const changedData = _.reduce(_.keys(data), (result, key) => {
			if (!Object.prototype.hasOwnProperty.call(initialData, key) || !_.isEqual(initialData[key], data[key])) {
				result[key] = data[key]
			}
			return result;
		}, {});
		if (_.isEmpty(changedData)) {
			toastr.warning('', 'This vehicle has no changes')
			return
		}

		const error = checkForm(changedData)

		const newData = { ...state, data: changedData }
		if (!['system_admin', 'customer_service'].includes(userRole) && _.get(state, 'data.data_limit')) {
			// TODO: a separate function to define the data to be saved by user roles
			delete state.data.data_limit
		}

		if (!error) {
			if (data.canbus !== initialData.canbus && data.canbus !== "0" && !confirmCanbus) {
				setshowConfirmCanbusModal(true);
				return
			}
			else if (data.canbus === initialData.canbus) {
				delete newData.data.canbus;
			}
			toggleLoader(true);

			updateDevice(newData, userRole, company, (err, res) => {
				// console.log('!updateDevice err, res: ', err, res)
				setTimeout(getDevice, 300)
				
				// Sends device config.txt to device
				if (err) {
					toggleLoader(false);
					console.log('!updateDevice sendForm error', err)

					toastr.error('An unexpected error occurred. Please try again later');
				} else {
					const params = {}
					if (data.vehicle_name !== initialData.vehicle_name) {
						params.IdInfo = { DvrName: data.vehicle_name }
					}
					if (data.cardreader !== initialData.cardreader) {
						const CardReader = { Enabled: data.cardreader }
						params.Peripherals = { CardReader }
					}
					if (newData.data.canbus) {
						const CanBus = { Type: newData.data.canbus * 1 }
						params.Peripherals = { CanBus }
					}
					// Adas updates
					if (data.adas_vehicle_hood_len !== initialData.adas_vehicle_hood_len) {
						if (params.Event && params.Event.Adas) {
							params.Event.Adas.VehicleHoodLength = data.adas_vehicle_hood_len * 1
						}
						else {
							const Adas = { VehicleHoodLength: data.adas_vehicle_hood_len * 1 }
							params.Event = { Adas }
						}
					}
					if (data.adas_camera_height !== initialData.adas_camera_height) {
						if (params.Event && params.Event.Adas) {
							params.Event.Adas.CameraHeight = data.adas_camera_height * 1
						}
						else {
							const Adas = { CameraHeight: data.adas_camera_height * 1 }
							params.Event = { Adas }
						}
					}
					if (data.adas_vehicle_width !== initialData.adas_vehicle_width) {
						if (params.Event && params.Event.Adas) {
							params.Event.Adas.VehicleWidth = data.adas_vehicle_width * 1
						}
						else {
							const Adas = { VehicleWidth: data.adas_vehicle_width * 1 }
							params.Event = { Adas }
						}
					}
					if (data.adas_camera_offset !== initialData.adas_camera_offset) {
						if (params.Event && params.Event.Adas) {
							params.Event.Adas.CameraOffset = data.adas_camera_offset * 1
						}
						else {
							const Adas = { CameraOffset: data.adas_camera_offset * 1 }
							params.Event = { Adas }
						}
					}
					// Sleep Mode updates
					if (data.sleep_mode_enabled !== initialData.sleep_mode_enabled) {
						const SleepMode = { Enabled: data.sleep_mode_enabled }
						params.Servers = { SleepMode }
					}

					toastr.success('', 'Successfully updated vehicle.');

					if (!_.isEmpty(params)) {
						sendDeviceConfiguration(JSON.stringify(params))
					} else {
						toggleLoader(false);
					}

					// FWSD-5295 - Sends webfleet setExternalObjectData request to update Rosco Live webfleet card
					if (initialData.webfleet.serial_number) sendWebfleetCard()
				}
			})
		}
	}

	const sendWebfleetCard = () => {
		const { data } = state;

		const params = {
			account: webfleetCredentials.account,
			username: webfleetCredentials.username,
			password: encodeURI(webfleetCredentials.password),
			access_hash: webfleetCredentials.access_hash,
			vehicle_name: data.vehicle_name,
			online: data.ads_online,
			webfleet_sn: data.webfleet.serial_number,
		}
		setWebfleetCard(params)
			.then()
			.catch(error => {
				console.log('sendWebfleetCard error:', error.response, error)
				toastr.error('An unexpected error of Rosco webfleet card updating.')
			})
	}

	const sendDeviceConfiguration = (params) => {
		const { device_id } = state.data;

		getConfigFile(userRole, device_id, true)
			.then(res => {
				let config = res.data.split('\n')
				console.log('config: ', config)
				config = fillConfig(config, params)

				let profileName = 'manual'
				if (config['IdInfo.FleetProfile']) {
					profileName = config['IdInfo.FleetProfile']
				}
				else if (state.data.config_checksum === 1 && state.data.pending_company_profile) {
					profileName = state.data.pending_company_profile
				}
				else if (state.data.uploaded_company_profile) {
					profileName = state.data.uploaded_company_profile
				}

				console.log('config1: ', config)
				let result = ''
				Object.keys(config).forEach((key) => {
					if (config.hasOwnProperty(key)) {
						result += `${key}=${((typeof config[key]) === 'string') ? `"${config[key]}"` : config[key]}\r\n`
					}
				})

				console.log('result: ', result)

				const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
				const file = new File([blob], 'config.txt', { type: 'text/plain;charset=utf-8' })

				pushConfigFile({
					userRole,
					deviceId: device_id,
					file,
					restart: true,
					profileName,
				})
					.then(res => {
						toggleLoader(false);
						toastr.success('Successfully sent config file to device')
					})
					.catch(error => {
						toggleLoader(false);
						console.log('!uploadConfig pushConfigFile error:', error.response, error)
						toastr.error('Unable to send config file to device')
					})
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getConfigFile error: ', error.response, error)
				let errDescription = 'Unable to send config file to device'
				if (error.response.data.response.error) {
					errDescription = errDescription + ': ' + error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const fillConfig = (config, profile) => {
		const res1 = {};
		const res2 = {}

		config.map((elem, index) => {
			const tmp = elem.split('=')
			const val = _.trim(tmp[1]).replace(/"/g, '')

			if (_.trim(tmp[0]) === '') {
				return false
			} if (val === '') {
				res1[_.trim(tmp[0])] = ''
			} else if (val === 'true') {
				res1[_.trim(tmp[0])] = true
			} else if (val === 'false') {
				res1[_.trim(tmp[0])] = false
			} else if (isNaN(val)) {
				res1[_.trim(tmp[0])] = val
			} else {
				res1[_.trim(tmp[0])] = val * 1
			}
		})

		profile = JSON.parse(profile)

		traverse(res2, profile)

		return { ...res1, ...res2 }
	}

	const traverse = (res, o, parent = '') => {
		let i;

		for (const k in o) {
			i = o[k]
			if (_.isArray(i)) {
				i.map((obj, index) => {
					if (parent) {
						if (obj.index) traverse(res, obj, `${parent}.${k}.${obj.index}`)
						else traverse(res, obj, `${parent}.${k}.${index}`)
					} else if (obj.index) traverse(res, obj, `${k}.${obj.index}`)
					else traverse(res, obj, `${k}.${index}`)
				})
			} else if (typeof i === 'object') {
				if (parent) traverse(res, i, `${parent}.${k}`)
				else traverse(res, i, k)
			} else {
				if (k == 'index') continue
				if (parent) {
					res[`${parent}.${k}`] = i
				} else {
					res[k] = i
				}
			}
		}
	}

	const checkForm = (data) => {
		let error = false;
		let errMessage = state.errMessage;

		// if (data.vehicle_name === 'undefined' || !data.vehicle_name) {
		// setState({ ...state, data: { ...state.data, vehicle_name: '' } })
		// }

		Object.keys(data).forEach((i) => {
			if (data[i] !== null) {
				if (data[i] === '' || data[i].length === 0) {
					error = true
					let message = i === 'groups' ? 'group' : i.replaceAll('_', ' ');
					errMessage['err' + i] = `Please enter a ${message}`
				}
				else if (i === 'vehicle_name' && (data[i].toString().trim() === '' || !/^[a-zA-Z0-9 ]+$/.test(data[i].toString()))) {
					error = true;
					errMessage['err' + i] = 'Please enter a valid vehicle name';
				}
				else if (i === 'vehicle_name' && data[i].length > 50) {
					error = true;
					errMessage['err' + i] = 'Please enter a name with less than 50 characters'
				}
				else if (errMessage['err' + i]) {
					errMessage['err' + i] = null
				}
			}
		})

		// FWSD-6805
		// if (!errMessage.errdata_threshold_pct && state.data.data_threshold_type === 'device' && data.data_threshold_pct < 100) {
		// 	error = true;
		// 	errMessage.errdata_threshold_pct = 'Enter a value at least 100'
		// }

		setState({ ...state, errMessage: errMessage })
		if (error) {
			toastr.error('', 'Vehicle form conatins wrong value')
		}
		return error
	}

	const changeVehicleIcon = (icon) => {
		setState({
			...state,
			data: { ...state.data, vehicle_icon: icon },
			iconDropdownOpen: false
		})
	}

	const onInputChange = (event, name = null) => {
		const { target } = event;
		const field = target.name || name;
		let value = target.type === 'checkbox' ? target.checked : target.value;

		switch (field) {
			case 'adas_units':
				value = value ? 'imperial' : 'metric'
				break;
			case 'disable_live_stream':
				value = !value;
				break;
		}

		// if (field === 'cardreader') {
		// 	setState({ ...state, data: { ...state.data, cardreader: value, pending_config: { ...state.data.pending_config, cardreader: value} } })
		// }
		// else {
		// 	setState({ ...state, data: { ...state.data, [field]: value } })
		// }
		setState({ ...state, data: { ...state.data, [field]: value } })
	}

	const onMetricInputChange = (e) => {
		const { target } = e;
		let { name, value } = target;

		value = state.data.adas_units === 'imperial' ? value * 2.54 : value;

		setState({
			...state,
			data: { ...state.data, [name]: value },
		})
	}

	const numberFormat = (argName, argValue = null) => {
		let value = (argValue || state.data[argName]) * 1

		if (state.data.adas_units === 'imperial') {
			value = value / 2.54
		} // ? (data.adas_vehicle_width / 2.54).toFixed(0) : data.adas_vehicle_width.toFixed(0)

		return value ? value.toFixed(0) : value
	}

	const onChangeGroups = (event) => {
		setState({
			...state,
			data: { ...state.data, groups: [event.target.value] }
		})
	}

	const hideModalApplyProfile = () => {
		setShowModalApplyProfile(false)
		checkingDevicesOfflineDisabled()
	}

	const hideConfigModal = () => {
		setState({ ...state, showConfigModal: false })
	}

	const hideAddInstallReportModal = () => {
		setState({ ...state, showAddInstallReportModal: false })
	}

	const hideModalInstallReport = () => {
		setState({ ...state, showModalInstallReport: false })
	}

	const showInstallReport = () => {
		setState({ ...state, showModalInstallReport: true })
	}

	const downloadConfig = () => {
		toggleLoader(true);
		const { device_id } = state.data;
		getConfigFile(userRole, device_id)
			.then((res, err) => {
				console.log('!downloadConfig getFirmware res', res.data);
				toggleLoader(false);
				let file = res.data;
				let blob = new Blob([file], { type: 'text/plain;charset=utf-8' });
				FileSaver.saveAs(blob, 'config.txt');
				toastr.success('Success', 'File downloaded');
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const handleRebootDeviceClick = () => {
		toggleLoader(true);
		apiCallGet('/device/{device_id}/restart', { device_id: state.data.device_id })
			.then((res, err) => {
				toggleLoader(false);
				console.log('!onRebootDeviceClick res, err: ', res, err)

				setState({ ...state, data: { ...state.data, ads_online: false } })
				toastr.success('Success', 'Reboot has been sent')
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!onRebootDeviceClick error:', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const setPropStates = (states, callback = null) => {
		setState({ ...state, ...states })
		if (typeof callback === 'function') {
			callback();
		}
	}

	const saveAdasCalibrationSettings = (argDevice) => {
		setState({ ...state, data: { ...state.data, ...argDevice } })
	}

	// FWSD-6482, If a device cannot be reached to complete the request (Offline/Disabled), display a modal informing the user the request cannot be completed that this time. 
	const checkingDevicesOfflineDisabled = () => {
		const { data } = state
		const { status, vehicle_name, device_id } = data

		if (['disabled','offline'].includes(status)) {
			setDevicesOfflineDisabled([vehicle_name || device_id])
		}
	}

	const { deviceId, data } = state;
	if (!data || !data.device_id) {
		return (
			<div className="edit-vehicle">
				<GreyHeader title="Edit Vehicle" hideSubtitle />
				No device id
			</div>
		)
	}

	const { initialData, errMessage, showModalProvisionDevice, showConfigModal, showDeleteModal, iconDropdownOpen, showModalInstallReport, showAddInstallReportModal, showModalDeviceArchiveHistory, provisionType } = state;
	const { errvehicle_name, errgroups, errdata_limit } = errMessage;
	// const firmware = location.state.firmware;

	const readOnly = (['user', 'installer', 'partner_view', 'storage_user', 'sales_demo'].indexOf(userRole) > -1
		|| (['custom_user', 'custom_partner'].includes(userRole) && !_.find(user.permissions, { perm_category_id: 5, a_edit: true })));
	const editVehicleNameAccess = ['system_admin', 'customer_service', 'fleet_manager', 'fleet_maintainer', 'partner'].includes(userRole)
		|| isCustomUserPermissions(userRole, userPermissions, 'vehicle_edit')
	const canViewProfile = ['system_admin', 'customer_service', 'fleet_manager', 'fleet_maintainer', 'partner', 'storage_manager'].includes(userRole)
		|| (['custom_user', 'custom_partner'].includes(userRole) && !_.find(user.permissions, { perm_category_id: 3, a_view: true }));
	// const hasPartnerAccess = [2171, 537].includes(user.company_id) && userRole === 'partner';
	// FWSD-5194, mismatch of device config.txt and database values
	const hasDiffPendingCardreader = data.pending_config && _.has(data.pending_config, 'cardreader') && data.pending_config.cardreader !== data.cardreader;
	const hasDiffPendingCanbus = data.pending_config && _.has(data.pending_config, 'canbus') && data.pending_config.canbus !== data.canbus;
	const hasDiffPendingAdasVehicleHoodLen = data.pending_config && _.has(data.pending_config, 'adas_vehicle_hood_len') && (+data.pending_config.adas_vehicle_hood_len).toFixed(0) !== (+data.adas_vehicle_hood_len).toFixed(0);
	const hasDiffPendingAdasCameraHeight = data.pending_config && _.has(data.pending_config, 'adas_camera_height') && (+data.pending_config.adas_camera_height).toFixed(0) !== (+data.adas_camera_height).toFixed(0);
	const hasDiffPendingAdasCameraOffset = data.pending_config && _.has(data.pending_config, 'adas_camera_offset') && (+data.pending_config.adas_camera_offset).toFixed(0) !== (+data.adas_camera_offset).toFixed(0);
	const hasDiffPendingAdasVehicleWidth = data.pending_config && _.has(data.pending_config, 'adas_vehicle_width') && (+data.pending_config.adas_vehicle_width).toFixed(0) !== (+data.adas_vehicle_width).toFixed(0);
	const hastExternalCredentials = geotabCredentials?.database || webfleetCredentials?.account;
	const isCompanyLiveStreamActive = company.company_data && company.company_data.live_active;
	const isCameraExceededDataThreshold = initialData.exceeded_livestream_limit;

	const displayDeviceProfileName = () => {
		const hasPendingProfile = canViewProfile && data.config_checksum === 1 && data.pending_company_profile && data.pending_company_profile.toLowerCase() !== 'manual'
		const hasUploadedProfile = canViewProfile && data.uploaded_company_profile && data.uploaded_company_profile.toLowerCase() !== 'manual'

		if (hasPendingProfile) {
			return (
				<span style={{ fontSize: '18px', display: 'inline-block', verticalAlign: 'bottom' }}>
					<span style={{ marginRight: '5px' }}>
						Pending update to
					</span>
					<CustomButton
						name="uploaded_company_profile"
						variant="link"
						onClick={() => browserHistory.push({
							pathname: '/managevehicles',
							state: { profile_name: data.pending_company_profile, tabKey: 2, device_id: deviceId }
						})}
					>
						{data.pending_company_profile}
					</CustomButton>
				</span>
			)
		}

		if (hasUploadedProfile) {
			return (
				<CustomButton
					name="uploaded_company_profile"
					variant="link"
					onClick={() => browserHistory.push({
						pathname: '/managevehicles',
						state: { profile_name: data.uploaded_company_profile, tabKey: 2, device_id: deviceId }
					})}
				>
					{data.uploaded_company_profile}
				</CustomButton>
			)
		}

		return <label className="control-label">{data.uploaded_company_profile || data.pending_company_profile || 'Manual'}</label>
	}

	// console.log('PROPS EditAssetContainer:', props)
	// console.log('STATE EditAssetContainer:', state)

	return (
		<div className="edit-vehicle">
			<GreyHeader title="Edit Vehicle" subtitle={data.vehicle_name || data.device_id} />

			<div className="page-subheader">
				<div className={classnames('subheader-section search-flex', { 'has-error': errvehicle_name })}>
					<label className="search-label">Name: </label>
					<input
						readOnly={!editVehicleNameAccess}
						type="text"
						name="vehicle_name"
						className="vehicle-search"
						onChange={onInputChange}
						value={data.vehicle_name}
					/>
					{errvehicle_name && <span className="help-block">{errvehicle_name}</span>}
				</div>
				{/** CONTROL BUTTONS */}
				<div className="subheader-section">
					{!readOnly && <CustomButton variant="secondary" onClick={() => saveVehicle(false)}>SAVE</CustomButton>}
					<CustomButton variant="secondary-outline" onClick={goBack}>BACK</CustomButton>
					{isInternalUser(userRole) && (
						<CustomButton variant="delete" onClick={() => setState({ ...state, showDeleteModal: true })}>DELETE</CustomButton>
					)}
				</div>
			</div>

			<main className="edit-asset-block main-content-block">
				{/** INFO */}
				<section className="info-section mb-30">
					<h3 className="category-title">
						<span className="mr-30">Info</span>
						<CustomButton
							variant="link"
							onClick={() => setState({ ...state, showModalDeviceArchiveHistory: true })}
						>
							View History
						</CustomButton>
					</h3>
					<div className="two-column-grid with-margin device-grid param-grid">
						<div className="param-wrapper">
							<div className="info-category">
								<h4 className="info-title">VEHICLE INFO</h4>
								<div className="device-param">
									<strong>License Plate:</strong> {data.tag_plate || 'Not Available'}
								</div>
								<div className="device-param">
									<strong>VIN:</strong> {data.vin_number || 'Not Available'}
								</div>
							</div>

							<div className="info-category manage-vehicles">
								<h4 className="info-title">CAMERA INFO</h4>
								{!!data.serial_number && (
									<div className="device-param">
										<strong>{isDv6(data) ? 'IMEI: ' : 'Serial Number: '}</strong> {data.serial_number}
									</div>
								)}
								{!!data.modem && data.modem.simcard_iccid && (
									<div className="device-param">
										<strong>ICCID:</strong> {data.modem.simcard_iccid}
									</div>
								)}
								<div className="device-param">
									<strong>First Connection:</strong> {data.activated_timestamp ? formatTimestamp(data.activated_timestamp, true) : 'Not Available'}
								</div>
								<div className="device-param fleet-activity-status">
									<strong>Status:</strong> {formatOnlineStatusLocal(null, data)}
									{/* {data.ads_online ? <span className="online-label">Online</span> : `Offline since ${formatTimestamp(data.last_disconnected_ts, true)}`} */}
								</div>
							</div>

							<div className="info-category">
								<h4 className="info-title">ADMIN INFO</h4>
								<div className="device-param">
									<strong>Device ID:</strong> {data.device_id}
								</div>
								{isInternalUser(userRole) &&
									<div className="device-param">
										<strong>Firmware:</strong> {data.firmware || 'Not Available'}
									</div>
								}
								{isInternalUser(userRole) &&
									<div className="device-param">
										<strong>Fleet Token:</strong> {company.token || 'Not Available'}
									</div>
								}
							</div>
						</div>
						{!!data.device_id &&
							<div className={`device-type ${isDv6(data) ? 'dv6' : 'dv4'}`} />
						}
					</div>

					<div className="button-wrapper">
						{data.installed_id && (
							<CustomButton
								variant="primary-outline" onClick={() => setState({ ...state, showModalInstallReport: true })}>
								Install Report
							</CustomButton>
						)}
						{isInternalUserWithPartner(userRole) && (
							<CustomButton variant="primary-outline" prefix={`${!data.ads_online && 'disabled'}`} disabled={!data.ads_online} onClick={handleRebootDeviceClick}>
								Reboot Device
							</CustomButton>
						)}
						{isInternalUserWithPartner(userRole) && data.device_type === 'dv6' &&
							<CustomButton
								variant="primary-outline"
								onClick={() => setShowModalDeviceReplace(true)}>
								Replace
							</CustomButton>
						}
					</div>
				</section>

				{/** SETTINGS */}
				<section className="settings-section">
					<h3 className="category-title">Settings</h3>
					<div className="profile-wrapper device-grid">
						<div className="info-category">
							<h4 className="info-title">VEHICLE SETTINGS</h4>
							<p>Choose how this vehicle will appear on Rosco Live and what group it will belong to.</p>
							<div className="three-column-grid device-grid align-bottom">
								<div className={classnames('', { 'has-error': errgroups })}>
									<strong className="device-param">Group:</strong>
									{(!readOnly && userRole !== 'group_manager') ?
										<span>
											<IconTooltip
												description="This is the group this camera belongs to. Users with access to this group will be able to see this camera."
												id="group-tooltip"
											/>
											<select
												placeholder="select"
												className={`rosco-select block ${readOnly && 'disabled'}`}
												name="groups"
												id="groups"
												value={data.groups[0] || ''}
												style={{ paddingTop: 0 }}
												onChange={onChangeGroups}
											>
												<option hidden value={''}>Select a Group...</option>
												{groupsList.map((group, index) =>
													<option key={group + index} value={group}>{group}</option>
												)}
											</select>
										</span> :
										<input className="vehicle-search" value={data.groups[0]} readOnly />
									}
									{errgroups && <span className="help-block">{errgroups}</span>}
								</div>
								{(['storage_manager', 'storage_user'].indexOf(userRole) === -1) && (
									<div className="flex-item">
										<strong className="device-param">Icon:</strong>
										{!readOnly ?
											<Dropdown
												autoClose
												id="icon-dropdown"
												className="icon-dropdown"
												show={iconDropdownOpen}
												onToggle={(isOpen) => setState({ ...state, iconDropdownOpen: isOpen })}
											>
												<Dropdown.Toggle>
													<Image src={vehicleIcons.filter((item) => item.value === state.data.vehicle_icon)[0].src} />
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<div className="icon-menu">
														{vehicleIcons.map(vehicle =>
															<div className="icon-wrapper" onClick={() => changeVehicleIcon(vehicle.value)} key={vehicle.name} value={vehicle.name}>
																<Image src={vehicle.src} />
															</div>
														)}
													</div>
												</Dropdown.Menu>
											</Dropdown> :
											<Image className="icon-dropdown" src={vehicleIcons.filter((item) => item.value === state.data.vehicle_icon)[0].src} />
										}
									</div>
								)}
							</div>
						</div>

						<div className="info-category">
							<h4 className="info-title">CAMERA SETTINGS</h4>
							<p>Changing these settings effect the camera settings or behavior.</p>
							<em>NOTE: Changes applied here will send a configuration change to the camera. The camera will reboot in order for these changes to take effect. <br />
								If the camera is currently offline, the changes will take effect on the next power on.</em>
							<div className="two-three-one-column-grid device-grid">
								<div>
									<div className="toggles-container align-top">
										<div>
											<strong className="device-param mr-30">Current Settings Profile:</strong>
											{displayDeviceProfileName()}
											{canViewProfile &&
												<div>
													<CustomButton
														variant="primary-outline"
														onClick={() => setShowModalApplyProfile(true)}
														disabled={!data.activated_timestamp}
													>
														Update Profile
													</CustomButton>
												</div>
											}
										</div>
										{(company.driver_recognition !== 'manual' && ['system_admin', 'customer_service', 'fleet_manager', 'group_manager', 'partner'].indexOf(userRole) > -1) && (
											<div className={classnames('', { 'has-error': errgroups })}>
												<div>
													<strong className="device-param">Driver ID:</strong>
													<IconTooltip
														description={`Must have a connected Driver ID card reader or compatible camera with Driver ID software. 
														${hasDiffPendingCardreader ? ' Pending Driver ID = ' + _.upperFirst(data.pending_config.cardreader) : ''} `}
														id="driver-id-tooltip"
													/>
												</div>
												
												{/*  
												The /devices/61291687 API returns following values according to the FWSD-3930 ticket: 
												cardreader = config_file.Peripherals.Cardreader.Enabled
												pending_config.cardreader = new_config_file.Peripherals.Cardreader.Enabled
												*/}
												<label>
													{/* <span className="toggle-left-title device-label">{ (data?.pending_config?.cardreader || data.cardreader) ? 'Enabled' : 'Disabled'}</span> */}
													<span className="toggle-left-title device-label">{ data.cardreader ? 'Enabled' : 'Disabled' }</span>
													<Toggle
														name="cardreader"
														icons={false}
														// checked={ data?.pending_config?.cardreader || data.cardreader || false }
														checked={ data.cardreader || false }
														onChange={onInputChange}
													/>
												</label>
												{hasDiffPendingCardreader &&
													<div>Pending <em>{data?.pending_config?.cardreader? 'Enabled' : 'Disabled' }</em></div>
												}
											</div>
										)}
									</div>
									{['fleet_manager', 'partner', 'system_admin', 'customer_service'].includes(userRole) && isDv6(data) &&
										<div>
											<label htmlFor="CAN Bus" className={`control-label ${hasDiffPendingCanbus ? 'has-pending-config' : ''}`}>
												<strong className="device-param">CAN Bus:</strong>
												{hasDiffPendingCanbus &&
													<IconTooltip
														description={` Pending CAN Bus = "${constants.canbusItems.find(({ value }) => value === data.pending_config.canbus).name || 'Different'}" `}
														id="canbus-tooltip"
													/>
												}
												<a href="https://wiki.roscocloud.com/user:product:roscolive2.0:how_to_guide:fleet_administration:fleet_configuration#can_bus" className="btn btn-link" target="_blank">
													Learn More
												</a>
											</label>
											<select
												placeholder="select"
												className="rosco-select block"
												name="canbus"
												id="canbus"
												value={state.data.canbus || "0"}
												onChange={onInputChange}
											>
												{
													constants.canbusItems.map(({ value, name }) => <option key={value} value={value}>{name}</option>)
												}
											</select>
										</div>
									}
								</div>

								{/* ADAS Calibration  //FWSD-5159*/}
								{isDv6(data) && (
									<div className="card-wrapper">
										<AdasCalibrationSettings
											device={data}
											// disabled={!isInternalUser(userRole) && !hasPartnerAccess}
											onSave={saveAdasCalibrationSettings}
										/>
									</div>
								)}
							</div>
						</div>

						{userRole === 'partner' && (
							<CameraEnableToggle
								isActive={data.active}
								onChange={onInputChange}
								addClass="inline"
							/>
						)}

						{isInternalUser(userRole) && (
							<div className="info-category">
								<h4 className="info-title">ADMIN SETTINGS</h4>
								<p>These settings are only available to Rosco Live team members and are not customer facing.</p>

								<div className="button-wrapper" style={{ marginLeft: 0 }}>
									{hastExternalCredentials &&
										<DropdownButton
											title="Pair To External Device"
											id="bg-vertical-dropdown-1"
											className="pair-external-devices"
										>
											{geotabCredentials?.database &&
												<Dropdown.Item eventKey="geotab" onClick={() => setState({ ...state, showModalProvisionDevice: true, provisionType: 'geotab' })}>Geotab Device</Dropdown.Item>
											}
											{webfleetCredentials?.account &&
												<Dropdown.Item eventKey="webfleet" onClick={() => setState({ ...state, showModalProvisionDevice: true, provisionType: 'webfleet' })}>Webfleet Device</Dropdown.Item>
											}
										</DropdownButton>
									}
									<CustomButton
										variant="primary-outline"
										onClick={() => setState({ ...state, showConfigModal: true })}>
										Remote Management
									</CustomButton>
									<CustomButton
										variant="primary-outline"
										onClick={downloadConfig}>
										Request Config
									</CustomButton>
									{!data.installed_id &&
										<CustomButton
											variant="primary-outline"
											onClick={() => setState({ ...state, showAddInstallReportModal: true })}>
											Add Install Report
										</CustomButton>
									}
								</div>
								<div className="button-wrapper" style={{ marginLeft: 0 }}>
									<CameraEnableToggle
										isActive={data.active}
										onChange={onInputChange}
										addClass={!isCompanyLiveStreamActive ? 'inline' : 'mr-20'}
									/>

									{isCompanyLiveStreamActive && (
										<div className="toggle-wrapper mr-20">
											<div className="device-label">
												Live Stream
												<IconTooltip
													description="Toggles Live Streaming capability for camera. If camera exceeds monthly live streaming minutes, will automatically be disabled."
													id="disable-vehicle-tooltip"
												/>
											</div>
											<label>
												<span className="toggle-left-title">{data.disable_live_stream ? 'Disabled' : 'Enabled'}</span>
												<Toggle
													name="disable_live_stream"
													icons={false}
													checked={!isCameraExceededDataThreshold && (!data.disable_live_stream || false)}
													onChange={onInputChange}
													disabled={isCameraExceededDataThreshold}
												/>
											</label>
										</div>
									)}

									<div className="toggle-wrapper mr-20">
										<div className="device-label">
											Sleep Mode
											<IconTooltip
												description="If enabled, the camera will go into Sleep mode when powering off. If disabled,	the camera will power off completely and will be offline until it powers on next."
												id="disable-sleepmode-tooltip"
											/>
										</div>
										<label>
											<span className="toggle-left-title">{data.sleep_mode_enabled ? 'Enabled' : 'Disabled'}</span>
											<Toggle
												name="sleep_mode_enabled"
												icons={false}
												checked={data.sleep_mode_enabled || false}
												onChange={onInputChange}
											/>
										</label>
									</div>
								</div>
							</div>
						)}
					</div>


					{/* <div className="three-column-grid with-margin device-grid">
						<div className={classnames('', { 'has-error': groups })}>
							<label htmlFor="timezone" className="control-label">Group: </label>
							{(!readOnly && userRole !== 'group_manager') ?
								<span>
									<IconTooltip
										description="This is the group this camera belongs to. Users with access to this group will be able to see this camera."
										id="group-tooltip"
									/>
									<select
										placeholder="select"
										className={`rosco-select block ${readOnly && 'disabled'}`}
										name="groups"
										id="groups"
										value={data.groups[0] || ''}
										onChange={onChangeGroups}
									>
										<option hidden value={''}>Select a Group...</option>
										{groupsList.map((group, index) =>
											<option key={group+index} value={group}>{group}</option>
										)}
									</select>
								</span> :
								<input className="vehicle-search" value={data.groups[0]} readOnly/>
							}
							{groups && <span className="help-block">{groups}</span>}
						</div>

						{['fleet_manager', 'partner', 'system_admin', 'customer_service'].includes(userRole) && isDv6(data) &&
							<div>
								<label htmlFor="CAN Bus" className={`control-label ${hasDiffPendingCanbus ? 'has-pending-config' : ''}`}>
									CAN Bus
									{hasDiffPendingCanbus &&
										<IconTooltip
											description={` Pending CAN Bus = "${ constants.canbusItems.find(({value}) => value === data.pending_config.canbus).name || 'Different'}" `}
											id="canbus-tooltip"
										/>
									}
									<a href="https://wiki.roscocloud.com/user:product:roscolive2.0:how_to_guide:fleet_administration:fleet_configuration#can_bus" target="_blank">
										Learn More
									</a>
								</label>
								<select
									placeholder="select"
									className="rosco-select block"
									name="canbus"
									id="canbus"
									value={state.data.canbus || "0"}
									onChange={onInputChange}
								>
									{
										constants.canbusItems.map(({value, name}) => <option key={value} value={value}>{name}</option>)
									}
								</select>
							</div>
						}

						{isInternalUser(userRole) && (
							<div className={classnames('', { 'has-error': data_limit })}>
								<label className="control-label">Override vehicle data limit</label>
								<select
									placeholder="select"
									className="rosco-select block"
									name="data_limit"
									id="data_limit"
									onChange={onInputChange}
									value={data.data_limit}
								>
									<option value="0">Unlimited</option>
									<option value="1">Use company limit</option>
									<option value="2">500 MB</option>
									<option value="3">1 GB</option>
								</select>
								{data_limit && <span className="help-block">{data_limit}</span>}
							</div>
						)}

						{ (['storage_manager', 'storage_user'].indexOf(userRole) === -1) && (
							<div className="flex-item">
								<label htmlFor="vehicle_icon" className="control-label">Icon:</label>
								{!readOnly ?
									<Dropdown
										id="icon-dropdown"
										className="icon-dropdown"
										open={iconDropdownOpen}
										onToggle={(isOpen) => setState({iconDropdownOpen: isOpen})}
										>
										<Dropdown.Toggle>
											<Image src={`/images/vehicle_icon/${data.vehicle_icon}.png`} responsive />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<div className="icon-menu">
												{vehicleIcons.map(vehicle =>
													<div className="icon-wrapper" onClick={() => changeVehicleIcon(vehicle.value)} key={vehicle.name} value={vehicle.name}>
														<Image src={`/images/vehicle_icon/${vehicle.value}.png`} responsive/>
													</div>
												)}
											</div>
										</Dropdown.Menu>
									</Dropdown> :
									<Image className="icon-dropdown" src={`/images/vehicle_icon/${data.vehicle_icon}.png`} responsive />
								}
							</div>
						)}

						{isInternalUser(userRole) && (
							<div>
								<label className="control-label">Contract ID</label>
								<input
									type="text"
									name="contract_id"
									className="vehicle-search"
									placeholder="Contract ID..."
									onChange={onInputChange}
									value={data.contract_id}
								/>
							</div>
						)}
					</div> */}
				</section>
				{/** VEHICLE DATA THRESHOLD */}
				{/* FWSD-6805
				{['system_admin'].includes(userRole) &&
					<VehicleDataThreshold
						data={data}
						errMessage={errMessage}
						setPropStates={setPropStates}
					/>
				} */}
			</main>

			{showModalProvisionDevice
				&& (
					<ModalProvisionDevice
						provisionType={provisionType}
						device={initialData}
						geotab_company={geotabCredentials}
						webfleetCredentials={webfleetCredentials}
						onHide={() => setState({ ...state, showModalProvisionDevice: false })}
						updateDeviceData={getDevice}
						getCompanyGeotabData={getCompanyGeotabCredentials}
					/>
				)}

			{showModalApplyProfile
				&& (
					<ModalChangeConfBatch
						userRole={userRole}
						// companyData={{partner_company: location.state.company.partner, company_name: location.state.company.name}}
						companyData={{ partner_company: state.data.partner_name, company_name: state.data.company_name }}
						selectedDevices={[initialData.device_id]}
						clearSelection={hideModalApplyProfile}
						onHide={hideModalApplyProfile}
						getCompanyDevices={getDevice}
					/>
				)}

			{showConfigModal
				&& (
					<ConfigModal
						device={initialData}
						onHide={() => setState({ ...state, showConfigModal: false })}
						user_role={userRole}
						clearSelection={checkingDevicesOfflineDisabled}
					/>
				)}

			{showDeleteModal
				&& (
					<VehicleDeleteModal
						device={data}
						userRole={userRole}
						onHide={() => setState({ ...state, showDeleteModal: false })}
					/>
				)}

			<Modal
				show={showConfirmCanbusModal}
				onHide={() => setshowConfirmCanbusModal(false)}
				dialogClassName="has-error modal-lg-size"
			>
				<Modal.Header closeButton>
					<Modal.Title className="delete-title">Are You Sure?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-text">
						<strong className="error-message">CAUTION:</strong> You must select the correct CAN BUS connection for this vehicle. Application of the wrong CAN BUS selection may cause interference
						with the vehicle's systems. If you are not sure right now, select "none" until you determine the correct connection type.
					</div>
				</Modal.Body>
				<Modal.Footer className="default-modal-footer modal-footer">
					<CustomButton
						variant="primary-outline"
						onClick={() => setshowConfirmCanbusModal(false)}
					>
						Cancel
					</CustomButton>
					<CustomButton
						variant="delete"
						onClick={() => {
							saveVehicle(true);
							setshowConfirmCanbusModal(false);
						}}
					>
						Confirm
					</CustomButton>
				</Modal.Footer>
			</Modal>

			{showAddInstallReportModal
				&& (
					<AddInstallReportModal
						device={initialData}
						onHide={() => setState({ ...state, showAddInstallReportModal: false })}
						user={user}
						showInstallReport={() => setState({ ...state, showModalInstallReport: false })}
					/>
				)}

			{showModalInstallReport
				&& (
					<ModalInstallReport
						device={initialData}
						onHide={() => setState({ ...state, showModalInstallReport: false })}
					/>
				)}


			{showModalDeviceReplace
				&& (
					<ModalDeviceReplace
						device={initialData}
						updateDeviceData={getDevice}
						onHide={() => setShowModalDeviceReplace(false)}
					/>
				)}

			{showModalDeviceArchiveHistory
				&& (
					<DeviceArchiveHistory
						device={initialData}
						onHide={() => setState({ ...state, showModalDeviceArchiveHistory: false })}
					/>
				)}

			{devicesOfflineDisabled.length > 0 &&
					<ModalDeviceOfflineDisabled
						devicesOfflineDisabled={devicesOfflineDisabled}
						onHide={() => setDevicesOfflineDisabled('')}
					/>
				}
		</div>
	)
}

EditAssetContainer.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(EditAssetContainer);
